<template>
    <span class="font-bold mt-2 block" v-if="buttons?.docker && !page?.run_template">App template:</span>
    <header class="sticky top-header left-0 bg-white z-20">
        <div class="flex justify-start gap-3 py-3" v-if="page?.run_template || buttons?.notebook">
            <NuxtLink :to="page?.run_template" v-if="page?.run_template"
                class="button--secondary py-[0.45rem] px-4 rounded-lg text-white inline">Deploy the App
            </NuxtLink>
            <div v-if="buttons?.notebook">
                <ButtonBadge type="notebook" :buttons="buttons" />
            </div>
        </div>
        <div class="flex mb-0 flex flex-col border-b border-gray-100 py-4"
            v-if="(buttons?.docker && !page?.run_template)" :class="{ 'mt-4': buttons?.notebook }">
            <div class="flex md:flex-row flex-col items-center mt-0 gap-4">
                <div class="max-w-full w-full">
                    <div class="relative border-gray-400 border rounded-lg overflow-hidden flex">
                        <div class="bg-gray-200 p-2 pw-text-black flex gap-2 items-center">
                            <Icon name="mdi:github" class="text-xl" />
                        </div>
                        <input type="text" readonly dir="rtl" :value="buttons.docker"
                            class="z-10 px-2 text-right text-sm w-full" />
                        <NuxtLink :to="buttons.docker" target="_blank"
                            class="p-2 px-3 bg-primary-500 text-white hover:bg-primary-800 transition">
                            <Icon name="heroicons:arrow-top-right-on-square-16-solid" />
                        </NuxtLink>
                    </div>
                </div>
                <div v-if="buttons?.docker && deployButtons" class="flex gap-4 md:ml-4 z-20">
                    <ButtonBadge type="docker" :buttons="buttons" />
                </div>
            </div>
        </div>
    </header>
</template>

<script setup>
const props = defineProps({
    article: Object,
    page: Object,
    buttons: Object,
    deployButtons: { type: Boolean, default: true },
})

const articleDate = new Date(props.article?.date)?.toLocaleString('en-EN', {
    month: 'long', day: 'numeric',
    year: 'numeric'
}) ?? ''
const articleTags = props.article?.tags?.join(' · ')?.toUpperCase() ?? ''
</script>
